import {
  CSSData,
  PromotionsResponse,
  Widgets,
} from '@chargeafter/types-session';
import { WidgetData } from '@chargeafter/types-widgets';
import { uuidv4 } from '@chargeafter/utils';

import { environment } from '../../../environments/environment';
import { logger } from '../../logger';
import { browserSessionId } from '../../utils/browserSessionId';
import { getUserKey } from '../../utils/storage';
import { refWindow } from '../../utils/windowRef';
import {
  isWidgetValid,
  validateWidgetData,
} from '../../validations/validations';

const filename = 'sdk.actions';
const refDocument = document;
const href = refWindow.location.href;

const fetchWidgetLogData = { filename, href, function: 'fetchWidgets' };

export const getContainersDataSet = () => {
  const { containerSelector, buttonSelector } = environment;

  const containers = refDocument.querySelectorAll(
    `${containerSelector}, ${buttonSelector}`
  );

  const promotionRequestPayload: WidgetData[] = [];
  const widgets: Widgets = {};

  containers.forEach((container: HTMLElement) => {
    const widget = validateWidgetData(container.dataset as WidgetData);
    const id = widget.widgetItemSku;

    if (
      isWidgetValid(widget) &&
      !widgets[id || widget.widgetType || widget.buttonType]
    ) {
      widgets[id || widget.widgetType || widget.buttonType] = {
        widget,
      };
      promotionRequestPayload.push(widget);
    }
  });

  return { widgets, promotionRequestPayload };
};

export const fetchWidgets = async ({
  apiKey,
  storeId,
  userId,
  language = 'en-US',
  widgets = [],
  currency,
  sendBrowserSessionStartedEvent,
}: {
  apiKey: string;
  storeId?: string;
  userId?: string;
  language?: string;
  widgets?: WidgetData[];
  currency?: string;
  sendBrowserSessionStartedEvent?: boolean;
}): Promise<PromotionsResponse | undefined> => {
  const flowId = `Frontend_${browserSessionId.get}_${uuidv4()}`;
  const userKey = getUserKey();

  try {
    const headers = JSON.stringify({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiKey}`,
      'x-dcode-flow': flowId,
      'x-ca-store-id': storeId,
      'x-ca-user-id': userId,
    });

    const content = JSON.stringify({
      items: widgets,
      storeId: storeId,
      userId: userId,
      language,
      currency,
      userKey,
      browserSessionId: browserSessionId.get,
      ...(widgets.length && {
        sendBrowserSessionStartedEvent,
        timestamp: browserSessionId.timestamp,
      }),
    });

    const body = new FormData();

    body.append('body', content);
    body.append('headers', headers);
    body.append('method', 'POST');

    const resp = await fetch(environment.promotionUrl, {
      credentials: 'include',
      method: 'POST',
      body,
    });

    const clonedResponse = resp.clone();

    return await resp.json().catch(async (error) => {
      logger.error('failed to parse response to json', {
        ...fetchWidgetLogData,
        error,
        url: environment.promotionUrl,
        apiKey,
        response: {
          status: clonedResponse?.status,
          statusText: clonedResponse?.statusText,
          body: await clonedResponse?.text?.(),
          headers: clonedResponse?.headers,
        },
        body: JSON.stringify({
          items: widgets,
          storeId,
          userId,
          language,
        }),
        errorMessage: error.message,
      });
    });
  } catch (error) {
    logger.error('fetching widgets failed', {
      ...fetchWidgetLogData,
      error,
      url: environment.promotionUrl,
      apiKey,
      body: JSON.stringify({
        items: widgets,
        storeId,
        userId,
        language,
      }),
      errorMessage: error.message,
    });
  }
};

export const handlePromotionsResponse = (
  widgets: Widgets,
  promotionResponse: PromotionsResponse
) => {
  let selector;
  const {
    promotions = [],
    merchant,
    lendersInWaterfall,
    canApplyFromWidget,
    configuration,
    configurationSessionId,
  } = promotionResponse;

  const basePromotionModalData = {
    merchant,
    lendersInWaterfall,
    canApplyFromWidget,
    configuration,
    configurationSessionId,
  };

  const returnedWidgets: Widgets = {};

  for (const promotion of promotions) {
    if (promotion.referenceId) {
      selector = `${
        environment.containerSelector
      }[data-widget-item-sku=${JSON.stringify(promotion.referenceId)}]`;
    } else {
      selector = `${
        promotion.buttonType
          ? environment.buttonSelector
          : environment.containerSelector
      }[data-${
        promotion.buttonType ? 'button' : 'widget'
      }-type=${JSON.stringify(promotion.widgetType || promotion.buttonType)}]`;
    }

    const id =
      promotion.referenceId || promotion.widgetType || promotion.buttonType;

    if (id in widgets) {
      returnedWidgets[id] = { ...widgets[id], selector, promotion };
      widgets[id].selector = selector;
      widgets[id].promotion = promotion;
    }
  }

  return { widgets: returnedWidgets, basePromotionModalData };
};

export const handleCss = (cssData: CSSData, isReplaceCss: boolean) => {
  const existedStyles = refDocument.getElementById(cssData.key);
  const caCss = existedStyles || refDocument.createElement('style');
  const csses: string[] = [];

  caCss.id = cssData.key;

  if (isReplaceCss) caCss.innerHTML = '';

  cssData.css.split(/(.*?\})/g).forEach((cssChunk) => {
    const chunks = cssChunk.split(/(?=\{)/g);

    if (chunks?.[0] && cssChunk) csses.push(cssChunk.trim());
  });

  csses.forEach((css) => {
    if (!caCss.innerHTML.includes(css))
      caCss.appendChild(refDocument.createTextNode(css));
  });

  if (!existedStyles)
    refDocument.head.insertBefore(caCss, refDocument.head.firstChild);
};
